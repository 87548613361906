<template>
    <div id="small-screen-message">
        <p>Diese Anwendung ist zur Zeit nicht mit Smartphones und anderen Geräten mit kleinen Bildschirmen kompatibel.</p>
        <p class="contact">Fragen und Anregungen nehme ich gerne per E-Mail entgegen.</p>
        <div class="highlighted"><a href="mailto:claudio.brasser@gymneufeld.ch">Claudio Brasser</a></div>
    </div>
</template>
<script>
export default {
    name: "SmallScreens",
}
</script>
<style scroped>
.highlighted {
  margin: auto;
}
#small-screen-message {
    height: 100%;
    font-size: 2rem;
    text-align: center;
    margin-top: 4rem;
    padding: 2rem;
}
.contact {
  color: #334155 !important;
  
  transition: all 0.3s ease-in-out;
}
.contact a {
    text-decoration: none;
    color: #334155 !important;
    display: block;
    padding: 4px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 4px;
  background-color: #fee2e2;
}

@media only screen and (max-width: 600px) {
  #small-screen-message {
    font-size: 1.3rem;
  }
}
</style>