<script>
const {storeRooms, storeClasses, loadRooms, loadClasses, clearData} = require('./../storage.js')
const download = require("downloadjs")
import MyButton from './ui/MyButton.vue'

export default {
  data() {
    return {
      
    }
  },
  components: {
    MyButton,
  },
  methods: {
    importData: function(event) {
        const files = event.target.files
        if (files.length > 1) {
            alert("Nur eine Datei erlaubt")
            return
        }
        const reader = new FileReader();
        const dataFile = files[0]
        reader.readAsText(dataFile, "UTF-8");
        reader.onload = (event) => {
            const data = JSON.parse(event.target.result)
            storeClasses(data.classes)
            storeRooms(data.rooms)
            this.$emit("dataImported")
            alert("Daten erfolgreich importiert")
        }
        reader.onerror = function () {
            alert("Error")
        }
        
    },
    exportData: function() {
        const classes = loadClasses()
        const rooms = loadRooms()
        const data = {
            classes: classes,
            rooms: rooms
        } 
        download(JSON.stringify(data), "Sitzplan_ch_Konfigurationsdatei.json", "text/plain");
    },
    resetData: function() {
        clearData()
        this.$emit("dataImported")
    }
  },
  created() {
  },
  mounted() {
   
  }
}
</script>

<template>
    <div class="data-menu">
            <MyButton class="button" :text="'Importieren'" :icon="'co-cloud-upload'"
            :tooltip="'Importiere eine Konfigurationsdatei um Klassen & Räume wiederherzustellen'"
                v-on:click="$refs.file.click()">
            </MyButton>
        <MyButton class="button"
        :text="'Exportieren'" 
        :icon="'co-cloud-download'" 
        :tooltip="'Exportiere deine Klassen & Räume als Backup in eine Konfigurationsdatei'"
        v-on:click="exportData()"></MyButton>
        <input v-on:change="importData" type="file" ref="file" style="display: none" />
        <div class="button" v-on:click="resetData()" style="display: none;">clear</div>
    </div>
</template>

<style scoped>
.data-menu {
    display: flex;
}
.data-menu .button {
    margin-right: 1rem;
}
.export-button {

}
.import-button {

}
</style>
