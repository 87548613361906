<template>
     <tippy :placement="'bottom'" :content="tooltip">
        <div :class="{'button-56': !small, 'my-button': small}">
            {{text}} <v-icon v-if="icon" :name="icon" />
        </div>
    </tippy>
</template>

<script>
import { Tippy } from 'vue-tippy';

export default {
    name: "MyButton",
    props: {
        text: String,
        tooltip: String,
        icon: String,
        small: Boolean
    },
    components: {
        Tippy
    }
}
</script>

<style>

.button.inactive .button-56 {
  cursor: none;
  pointer-events: none;
  
}

.button.inactive .button-56:after {
  transform: translate(0, 0);
  opacity: 0;
}

/* CSS */
.button-56 {
  align-items: center;
  background-color: #fee6e3;
  border: 2px solid #111;
  border-radius: 8px;
  box-sizing: border-box;
  color: #111;
  cursor: pointer;
  display: flex;
  font-family: Inter,sans-serif;
  font-size: 16px;
  height: 48px;
  justify-content: center;
  line-height: 24px;
  max-width: 100%;
  padding: 0 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: max-content;
  transition: all 0.2s ease-in-out;
}
.button-56 svg {
    margin-left: 0.7rem;
}

.button-56:after {
  background-color: #111;
  border-radius: 8px;
  content: "";
  display: block;
  height: 48px;
  left: 0;
  width: 100%;
  position: absolute;
  top: -2px;
  transform: translate(8px, 8px);
  transition: all .2s ease-out;
  z-index: -1;
}

.button-56:hover:after {
  transform: translate(0, 0);
}

.button-56:active {
  background-color: #ffdeda;
  outline: 0;
}

.button-56:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-56 {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 1100px) {

 .button-56 {
  height: 36px;
  padding: 0 20px;
  margin-bottom: 1rem;
 }
 .button-56::after {
  height: 36px;
  transform: translate(6px, 6px);
 }

}
.my-button {
    background-color: #000000;
    position: relative;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    cursor: pointer;
    color: #FCFEFC;
    width: max-content;
}
.my-button::after {
    background-color: #F3F461;
    top: 0;
    left: 0;
    transform: translate(4px, 4px);
    z-index: -1;
}
.my-button::before, .my-button::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
}
.my-button::before {
    background-color: #943737;
    transform: translate(8px, 8px);
    z-index: -2;
    top: 0;
    left: 0;
}
.my-button:hover::after {
    transform: translate(2px, 2px);
}
.my-button:hover::before {
    transform: translate(4px, 4px);
}
</style>