import { v4 as uuidv4 } from 'uuid';

export function getEmptyRoomConfig() {
    let config = {
        name: "0.54",
        tables: [],
        id: uuidv4(),
        rows: 12,
        cols: 12,
    }
    for (let i = 0; i < 12 * 12; i++) {
            let table = {
                student: {},
                index: i,
                available: false,
            }        
            config.tables.push(table)
    }
    return config;
}

export function createRoomFromName(name) {
    let config = {
        name: name,
        tables: [],
        id: uuidv4(),
        rows: 12,
        cols: 10,
    }
    for (let i = 0; i < config.rows * config.cols; i++) {
        let table = {
            studentId: "",
            index: i,
            available: false,
        }        
        config.tables.push(table)
    }
    return config
}
export function changeRoomSize(room, newRows, newCols) {
    room.rows = Math.min(newRows, 10);
    room.cols = newCols
    room.tables = []
    for (let i = 0; i < room.rows * room.cols; i++) {
        let table = {
            studentId: "",
            index: i,
            available: false,
        }        
        room.tables.push(table)
    }
    return room
}

export function getEmptyClassConfig() {
    let config = {
        students: [],
        name: "25Fz",
        id: uuidv4(),
    }
    return config;
}

export function createClassFromName(name) {
    let config = {
        students: [],
        name: name,
        id: uuidv4(),
    }
    return config;
}

export function createClassFromNameAndStudents(name, students) {
    let config = {
        students: students.map(stud => createStudent(stud)),
        name: name,
        id: uuidv4(),
    }
    return config;
}

export function createStudent(name) {
    return {
        name,
        id: uuidv4(),
        blockingGroup: 0,
    }
}