<template>
  <div>
    <SmallScreens></SmallScreens>
    <RouterView />
    
  </div>
</template>

<script>
import SmallScreens from './components/SmallScreens.vue';


export default {
  name: 'App',
  components: {
    SmallScreens
  }
}
</script>


<style lang="scss">
@import "./assets/colors.scss";

.copyright {
  font-size: 0.9rem;
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 8;
}
.copyright .highlighted {
  margin-bottom: 0.5rem;
}
.highlighted .icon {
  margin-right: 0.4rem;
}
.copyright .coffee {
}
.copyright .coffee img {
  width: 150px;
}

.copyright:hover {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.small-hint {
  opacity: 0.7;
  font-size: 0.9rem;
}

h2 {
  margin-top: 0;
}

html, body {
  padding: 0;
  margin: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text;
  font-size: 16px;
  padding: 0;
  margin: 0;
}
.tippy-content {
  font-family: Avenir, Helvetica, Arial, sans-serif;
}

.input-group {
  position: relative;
  width: max-content;
  margin-top: 1rem;
  margin-bottom: 1rem;
  z-index: 11;
}
.input-group .confirm {
 display: flex;
 justify-content: center;
 align-items: center;
 height: 44px;
 width: 44px;
 background-color: black;
 position: absolute;
 top: 0;
 right: 0;
 transition: all 0.3s ease-in-out;
 z-index: -1;
}
.input-group .confirm svg{
  color: white;
  transform: scale(1.3);
}
.input-group:has(> input.valid) .confirm {
  transform: translateX(100%);
}

input {
  outline: none;
  background-color: white;
  transition: 0.2s ease-in-out;
  border: none;
  border: 2px solid black;
  filter: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08));
  font-size: 1rem;
  width: 200px;
  height: 20px;
  padding: 10px;
  margin: 0;
}
input::after {
  height: 100%;
  width: 100%;
  content: "";
  position: absolute;
  background-color: black;
  top: 0;
  left: 0;
}
.import-module input::after {

  content: "";
  position: relative;
  background-color: white;

}
.import-module input {
  width: max-content
}
input:active::after {
  transform: translate(2px,2px);
}

input.valid {
  border: 2px solid #a3be8c;
}


.icon {
  transition: 0.2s ease-in-out;
}


#small-screen-message {
  display: none;
}

/* Very small screens, app not available */
@media only screen and (max-width: 699px) {
  .app, .homepage {
    display: none !important;
  }
  #small-screen-message {
    display: block;
  }
}

@media only screen and (max-height: 550px) {
  .app, .homepage {
    display: none !important;
  }
  #small-screen-message {
    display: block;
  }
}

/* Medium Screens, adjust scaling */
@media only screen and (max-width: 1000px) {



}

.highlighted {
    background-color: #000000;
    position: relative;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    color: #FCFEFC;
    width: max-content;
}
.highlighted::after {
    background-color: #F3F461;
    top: 0;
    left: 0;
    transform: translate(3px, 3px);
    z-index: -1;
}
.highlighted::before {
    background-color: #943737;
    transform: translate(5px, 5px);
    z-index: -2;
    top: 0;
    left: 0;
}
.highlighted::before, .highlighted::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.3s ease-in-out;
}

.highlighted:hover::after {
    transform: translate(1px, 1px);
}
.highlighted:hover::before {
    transform: translate(3px, 3px);
}
.highlighted a {
  color: white !important;
  text-decoration: none;
}

</style>
