<template>
        <div 
        class="table" 
        @click="click()"
        @click.right.prevent="rightClick()"
        :class="{available : available, selected: selectedForSpeaking}">
            {{ studentName }}
        </div>
</template>

<script>

export default {
  name: 'Table',
  data() {
    return  {
    } 
  },
  props: {
    studentId: String,
    index: Number,
    available: Boolean,
    selectedClass: Object,
    selectedForSpeaking: Boolean
  },
  components: {
  },
  computed: {
    studentName() {
        return this.selectedClass && this.selectedClass.students && this.studentId && this.selectedClass.students.find(s => s.id === this.studentId) ? this.selectedClass.students.find(s => s.id === this.studentId).name : ''
    }
  },
  methods: {
    click: function() {
        this.$emit('toggle')
    },
    rightClick: function() {
      alert("This feature is in development")
      this.$emit('toggleTeacherTable')
    }
},
}
</script>


<style scoped lang="scss">
@import "./../assets/colors.scss";

.table {
  border-radius: 2px;
  background-color: white;
  cursor: pointer;
  font-size: 1rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.1s ease-in-out;
  transition: scale 0.1s ease-in-out;
  transition: box-shadow 0.1s ease-in-out;
  text-align: center;
  color: white;
}

.table.available {
    background-color: rgb(120, 176, 185);
    background-color: black;
}
.table.selected {
  background-color: $primary;
  color: black;
  scale: 1.1;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
</style>