<template>
    <div class="overlay">
        <div class="overlay-module-wrapper group-builder-wrapper">
        <div class="overlay-module-content group-builder-content">
            <div @click="closeGroupBuilder" class="overlay-module-close"><v-icon  name="px-close-box" scale="1.4" /></div>
            <h2>Teams bilden - {{ config.name }}</h2>
            <div class="options">
                <div class="group-settings">
                    <h3>Teamgrösse</h3>
                    <div class="group-sizes">
                            <div v-for="number in 13" :key="number"
                            class="group-size" :class="{active: groupSize == number}"
                            v-on:click="setGroupSize(number)">{{ number }}</div>
                    </div>
                </div>
                <div class="group-settings">
                    <h3>Lernende</h3>
                    <div class="small-hint">Abwesende Lernende - Klicken Sie Lernende an um Sie als abwesend zu markieren</div>
                        <div class="student-list">
                            <div class="student" @click="toggleStudent(student.id)" :class="{absent: isAbsent(student.id)}" v-for="student in config.students" :key="student.id">{{ student.name }}</div>
                        </div>
                </div>
                <MyButton :text="'Teams erstellen'" @click="buildGroups"></MyButton>
            </div>
            <div class="groups">
                <h3>Teams</h3>
                <div class="small-hint" v-if="groups.length == 0">Noch keine Teams</div>
                <div class="group-container">
                    <div class="group" v-for="(group, index) in groups" :key="index">
                        <h4>Team {{ index + 1 }}</h4>
                        <div class="group-members">
                            <div class="student" v-for="student in group" :key="student.id">{{ student.name }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

</template>

<script>
import MyButton from './ui/MyButton.vue'
export default {
    name: "GroupBuilder",
    data() {
        return {
            groupSize: 2,
            absentPeople: [],
            groups: []
        }
    },
    components: {
        MyButton
    },
    props: {
        config: Object,
        open: Boolean,
    },
    watch:{
        config: function() {
            this.groups = []
            this.absentPeople = []
        }
    },
    methods: {
        closeGroupBuilder: function() {
            this.$emit("closeGroupBuilder")
        },
        setGroupSize: function(number) {
            this.groupSize = number
        },
        buildGroups: function() {
            
            let foundValidConfig = false
            let counter = 0
            while(!foundValidConfig) {
                counter += 1
                if (counter > 100) {
                    alert("Konnte keine gültige Konfiguration finden")
                    return
                }
                this.groups = []
                const randomStudentOrder = this.config.students.filter(s => !this.absentPeople.find(p => p == s.id)).map(student => ({student, sort: Math.random()})).sort((a,b) => a.sort - b.sort).map(({student}) => student);
                let numGroups = Math.ceil(randomStudentOrder.length / this.groupSize) 
                for (let i = 0; i < numGroups; i++) {
                    let group = randomStudentOrder.slice(i*this.groupSize, (i+1)*this.groupSize)
                    this.groups.push(group)
                }
                // check if groups valid
                let allGroupsValid = true
                this.groups.forEach( g => {
                    let blockGroupNumbers = {
                        0: 0,
                        1: 0,
                        2: 0,
                        3: 0
                    }
                    g.forEach(s => {
                        blockGroupNumbers[s.blockingGroup] = blockGroupNumbers[s.blockingGroup] + 1
                    })
                    if (blockGroupNumbers[1] > 1 || blockGroupNumbers[1] > 2 || blockGroupNumbers[3] > 1) {
                        allGroupsValid = false
                    }
                })
                if (allGroupsValid) {
                    foundValidConfig = true
                }

            }    

        },
        toggleStudent: function(id) {
            if (this.absentPeople.find(p => p == id)) {
                this.absentPeople = this.absentPeople.filter(p => p != id)
            } else {
                this.absentPeople.push(id)
            }
        },
        isAbsent: function(id) {
            console.log('test')
            return this.absentPeople.find(p => p == id)
        }
    }
}

</script>

<style scoped lang="scss">
@import "./../assets/colors.scss";
@import "./../assets/overlays.scss";

.options {
    position: relative;
    z-index: 12;
}
.group-settings h3 {
    margin-top: 1rem;
    margin-bottom: 0.6rem;
}
.group-builder-wrapper {
    width: 95vw;
    height: 95vh;
}
.group-builder-content {
    overflow-y: scroll;
}
.group-sizes {
    display: flex;
    z-index: 20;
    position: relative;
}
.group-sizes .group-size {
    margin-right: 1rem;
    background-color: white;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    color: black;
    position: relative;
    border: 3px solid black;
}
.group-sizes .group-size.active {
  cursor:default;
}
.group-sizes .group-size::after {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: all 0.2s ease-in-out;
  content: "";
  z-index: -1;
}
.group-sizes .group-size.active::after {
  transform: translate(8px, 8px) scale(1.02);
}
.group-settings {
    margin-bottom: 0.5rem;
}
.option-label {
    font-size: 0.8rem;
    opacity: 0.8;
    margin-right: 0.4rem;
}
.small-hint {
    font-size: 0.8rem;
    opacity: 0.8;
}
.group-container {
    display: flex;
}
.group {
    padding: 0.4rem;
    border: 2px solid black;
    margin-right: 1rem;
}
.group h4 {
    margin-top: 0;
    margin-bottom: 0.2rem;
}
.group-members {
    display: flex;
    flex-direction: column;

}
.student-list {
    display: flex;
    flex-wrap: wrap;
}
.student {
    padding: 4px;
    margin-right: 0.4rem;
    margin-bottom: 0.4rem;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
    border: 2px solid black;
}
.student.absent {
    text-decoration: line-through;
}

.close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  @media only screen and (max-width: 1100px) {
    .group-container {
        flex-wrap: wrap;
    }
    .group {
        margin-bottom: 1rem;
    }

  }


</style>