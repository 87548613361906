<template>
    <div class="fullscreen homepage">
        <div class="content">
            <div class="cont-left">
                <div class="anim-container">
                        <div class="cell" id="cell1"></div>
                        <div class="cell" id="cell2"><div class="name" id="name1">Anna</div></div>
                        <div class="cell" id="cell3"><div class="name" id="name2">Max</div></div>
                        <div class="cell" id="cell4"></div>
                        <div class="cell" id="cell5"><div class="name" id="name3">Luisa</div></div>
                        <div class="cell" id="cell6"></div>
                        <div class="cell" id="cell7"></div>
                        <div class="cell" id="cell8"></div>
                        <div class="cell" id="cell9"></div>
                        <div class="cell" id="cell10"></div>
                        <div class="cell" id="cell11"></div>
                        <div class="cell" id="cell12"></div>
                        <div class="cell" id="cell13"></div>
                        <div class="cell" id="cell14"></div>
                        <div class="cell" id="cell15"></div>
                        <div class="cell" id="cell16"></div>
                </div>
            </div>
            <div class="cont-right">
                <div class="wrapper">
                <h1>Sitzplan.ch</h1>
                <p class="subtitle">Zufällige Sitzpläne & Gruppenarbeiten fürs Klassenzimmer</p>
                <div class="tag">
                    <v-icon class="t-icon" name="md-moneyoff-outlined"  scale="1.8"/>
                    <div class="tag-right">
                        <div class="tag-header">Gratis & Privat</div>
                        <div>Dieses Tool ist gratis und Werbeifrei nutzbar. Deine Klassen & Zimmer werden auf deinem Gerät lokal gespeichert - Es landet nichts im Internet!</div>
                    </div>
                    
                </div>
                <div class="tag">
                    <v-icon class="t-icon" name="px-teach"  scale="1.8"/>
                    <div class="tag-right">
                        <div class="tag-header">Made with Love</div>
                        <div>Dies ist ein Projekt von mir als Lehrperson für Lehrpersonen</div>
                    </div>
                </div>
                <div class="tag">
                    <v-icon class="t-icon" name="px-mail"  scale="1.8"/>
                    <div class="tag-right">
                        <div class="tag-header">Kontakt</div>
                        <div>Fragen & Anregungen nehme ich gerne via <a href="mailto:claudio.brasser@gymneufeld.ch">E-Mail</a> entgegen</div>
                    </div>
                </div>
                <div class="tag">
                    <v-icon class="t-icon" name="px-coffee-alt"  scale="1.8"/>
                    <div class="tag-right">
                        <div class="tag-header">Support</div>
                        <div>Falls du die Entwicklung dieses Projekts unterstützen möchtest, kannst du dies freiwillig über <a target="_blank" href="https://buymeacoffee.com/cbrasser">diesen Link</a> tun</div>
                    </div>
                </div>
                <MyButton @click="goToTool" :text="'Los gehts!'"></MyButton>

            </div>
            </div>


        </div>
        

    </div>
  </template>
  
  <script>
import MyButton from './components/ui/MyButton.vue';


  export default {
    name: 'Home',
    components: {
        MyButton
    },
    methods: {
        goToTool: function() {
            this.$router.push('/generator')
        }
    }
  }
  </script>
  
  
  <style scoped lang="scss">
  @import "./assets/colors.scss";

a {
    color: black;   
}
  
.fullscreen {
    display: flex;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: white;
  
}

.content {
    width: 80%;
    height: 80%;
    display: flex;
    position: relative;
}

.cont-bottom {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    z-index: 1;
    animation: slide-in-bottom 3.8s ease-in-out 0s 1 forwards;
    display: flex;
}

.tag {
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
}
.tag p {
    margin: 0;
}
.tag-header {
    font-weight: bold;
}
.t-icon {
    margin-right: 0.5rem;
}

h1 {
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: bold;
    margin-bottom: 0.1rem;
    margin-top: 0;
}
.subtitle {
    margin-bottom: 1rem;
    margin-top: 0rem;
    opacity: 0.8;
}
.cont-left {
    z-index: 3;
    display: flex;
    align-items: center;
    background-color: white;
}
.cont-right {
    
    display: flex;
    align-items: center;
}
.cont-right .wrapper {
    animation: slide-in 3s ease-in-out 0s 1 forwards;
    padding-left: 2rem;
    z-index: 2;
    max-height: max-content;
}

@keyframes slide-in {
  0%   {
    transform: translateX(-100%);
    opacity: 0;
    z-index: -1;                
    }
  80%   {
    transform: translateX(-100%);
    opacity: 0;
    z-index: -1;
}
90% {
    opacity: 0;
} 
  100%   {
    transform: translateX(0%);
    opacity: 1;
    z-index: 2;
}
}

@keyframes slide-in-bottom {
  0%   {
    transform: translateY(-100%);
    opacity: 0;                
    }
  80%   {
    transform: translateY(-100%);
    opacity: 0;
}
90% {
    opacity: 0;
}
  100%   {transform: translateY(0%);
opacity: 1;}
}



.anim-container {
    display: grid;
    grid-template-columns: repeat(4, 100px);
    grid-template-rows: repeat(4, 60px);
    grid-gap: 4px;
    background-color: black;
    border: 4px solid black;
    height: max-content;
  }

  .cell {
    background-color: white;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .name {
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 3px;
    opacity: 0%;
  }

  #name1 {
    animation: name-appear 0.6s ease-in-out 3.4s 1 forwards;
  }
  #name2 {
    animation: name-appear 0.6s ease-in-out 3.2s 1 forwards;
  }
  #name3 {
    animation: name-appear 0.6s ease-in-out 3.7s 1 forwards;
  }

  @keyframes name-appear {
  0%   {opacity: 0%;}
  100% {opacity: 100%;}
}

  @keyframes activate-table {
  0%   {background-color: white;}
  100% {background-color: black;}
}
@keyframes toggle-table {
  0%   {background-color: white;}
  30% {background-color: black;}
  70% {background-color: black;}
  100% {background-color: white;}
  
}

#cell4 {
    animation: toggle-table 2s ease-in-out 0.3s 1 forwards;
}
#cell5 {
    animation: activate-table 0.8s ease-in-out 1.2s 1 forwards;
}
#cell9 {
    animation: activate-table 0.8s ease-in-out 1.9s 1 forwards;
}
#cell2 {
    animation: activate-table 0.8s ease-in-out 2.1s 1 forwards;
}
#cell3 {
    animation: activate-table 0.8s ease-in-out 3.0s 1 forwards;
}


@media only screen and (max-width: 1000px) {
  .content {
    flex-direction: column;
  }
  .cont-right .wrapper {
    animation: slide-in-bottom 3s ease-in-out 0s 1 forwards;
    padding-left: 1rem;
    padding-top: 2rem;
    z-index: 2;
    max-height: max-content;
}
}


@media only screen and (max-width: 1000px) {
  .cont-left {
    justify-content: center;
  }
  .content {
    width: 90%;
    height: 90%;
  }
  .homepage {
    overflow-y: scroll;
  }
}
  
  </style>
  