<template>
  <div class="overlay">
    <div class="overlay-module-wrapper">
      <div class="overlay-module-content class">
        <div @click="closeClassMenu" class="overlay-module-close"><v-icon  name="px-close-box" scale="1.4" /></div>
        <div @click="openClassSecretSettings" class="overlay-module-additional-open">
          <tippy content="Klicke eine:n Schüler:in an, um die Person einzufärben. Personen mit der gleichen Farbe werden nicht nebeneinander platziert und nicht in die selbe Gruppe eingeteilt.">
            <v-icon  name="bi-question-square-fill" />
          </tippy>
        </div>
          
            <h2>Klasse bearbeiten</h2>
            <h3>Name</h3>
            <div class="input-group">
              <input :class="{valid: className.length > 0 && className != config.name}" v-model="className" v-on:keyup.enter="submitClassName()">
              <div class="confirm"  @click="submitClassName"><v-icon  name="md-saveas-outlined" /></div>
            </div>
            
            
            <h3>Schüler:innen [{{ config.students.length }}]</h3>
        <div class="class-wrapper">
            <div class="class-form">
                <div class="input-group">
                    <input :class="{valid: studentName.length > 0}" class="new-student" type="text" placeholder="Neue:r Schüler:in" name="studentName" v-model="studentName" v-on:keyup.enter="addStudent()">
                    <div class="confirm" @click="addStudent()"><v-icon class="icon" name="io-add-circle" /></div>
                </div>
            </div>
            <div class="class-list">
            <draggable 
            v-model="studentsCopy"
            :component-data="{ tag: 'div', }"
            item-key="id"
            @start="drag=true" 
            @end="drag=false" 
            direction="horizontal"
            >
              <template #item="{element}">
                <div class="student-wrapper">
                  <div class="student" :class="`blocking-group-${element.blockingGroup}`">
                        <div class="student-name" @click="changeBlockingGroup(element.id)">{{element.name}}</div>
                        <div class="student-remove" @click="removeStudent(element.id)"><v-icon class="icon"  name="io-remove-circle" /></div>
                </div>
              </div>
              </template>
                    
            </draggable>
          </div>

        </div>

    </div>
    </div>
  </div>
      
  </template>
  
  <script>
  import { Tippy } from 'vue-tippy';
  import draggable from 'vuedraggable'

  export default {
    name: 'Klasse',
    data() {
      return  {
        className: "",
        studentName: "",
        drag: false,
        studentsCopy: [],
        myList: [
         {id: 1, key: "a"},
         {id: 2, key: "b"},
         {id: 3, key: "c"},
        ]
      } 
    },
    created() {
        this.className = this.config.name
        this.studentsCopy = this.config.students
    },
    mounted() {
        this.className = this.config.name
        this.studentsCopy = this.config.students
    }, 
    props: {
      config: Object,
      open: Boolean,
    },
    components: {
      draggable,
      Tippy,
    },
    computed: {
    },
    watch: {
        config: function() {
            this.className = this.config.name
            this.studentName = "";
            this.studentsCopy = this.config.students;
        }
    },
    methods: {
        changeBlockingGroup: function(id) {
          let student = this.studentsCopy.find(s => s.id === id)
          student.blockingGroup = (student.blockingGroup + 1) % 4
          this.$emit("updateStudents", this.studentsCopy)
        },
        addStudent: function() {
            this.$emit("addStudent", this.studentName);
            this.studentName = "";
        },
        removeStudent: function(id) {
            this.studentsCopy = this.studentsCopy.filter(s => s.id !== id);
            this.$emit("updateStudents", this.studentsCopy)
        },
        submitClassName: function() {
            this.$emit("changeClassName", this.className);
        },
        closeClassMenu: function() {
          this.$emit("closeClassMenu")
        },
        openClassSecretSettings: function() {

        }
    }
  }
  </script>


  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  @import "./../assets/colors.scss";
  @import "./../assets/overlays.scss";

.class-list .student-wrapper {
  display: inline-block;
  margin-right: 0.5rem;
}
  .overlay-module-additional {

}
.overlay-module-additional-open {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.class h3{
  margin-bottom: 0.4rem;
}

.class-list {
    flex-basis: 80%;
    flex-shrink: 0;
    display: flex;
}
.class-form {
    flex-basis: 1;
}
  .student-list {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;

    align-content: flex-start;
  }
  .student {
    height: max-content;
    display: flex;
    justify-content: space-between;
    background-color: #f8fafc;
    padding: 5px;
    border: 2px solid black;
    margin-top: 4px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }
  .student.blocking-group-1 {
    background-color: #25b1bf;
  }
  .student.blocking-group-2 {
    background-color: #ff6366;
  }
  .student.blocking-group-3 {
    background-color: #8FBC8F;
  }
  .student-remove {
    cursor: pointer;
    margin-left: 0.6rem;
  }

  @media only screen and (max-width: 1100px) {
    .overlay-module-content.class {
      overflow-y: scroll;
      padding: 1rem;
    }
  }

  </style>
  