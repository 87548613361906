<template>
    <div class="room-wrapper">
      <div class="room" :style="gridStyle" v-if="!isMirrored">
        <Table v-for="table in config.tables" v-bind:key="'table' + table.index" :selectedClass="selectedClass" :studentId="table.studentId" :selectedForSpeaking="table.studentId == currentlySelectedStudent" :index="table.index" :available="table.available" v-on:toggle="toggleTable(table.index)">
        </Table>
      </div>
      <div class="room" :style="gridStyle" v-else>
        <Table v-for="table in reversedTables" v-bind:key="'table' + table.index" :selectedClass="selectedClass" :studentId="table.studentId" :selectedForSpeaking="table.studentId == currentlySelectedStudent" :index="table.index" :available="table.available" v-on:toggle="toggleTable(table.index)">
        </Table>
      </div>
      <div class="subtitle">
          <p>
            {{ selectedClass.students.length }} Schüler:innen in Klasse | {{config.tables.filter(t => t.available).length}} Tische ausgewählt
          </p>
          <div class="mini-table"></div>
          
      </div>
      
      <div class="button-group">
        <div class="left">
          <MyButton class="button" :class="{inactive: !(selectedClass.students.length <= config.tables.filter(t => t.available).length && selectedClass.students.length > 0)}"  :text="'Zufällige Ordnung 🎲'"  @click="generateOrder()"></MyButton>
          <MyButton class="button"  :text="'Gruppenarbeit 👫'" @click="openGroupBuilder"></MyButton>
          <MyButton class="button"  :text="'Zufällig auswählen 🙋‍♀️'" @click="startSelection"></MyButton>
          <MyButton class="button"  :text="'Zimmer Spiegeln'" :class="{active: isMirrored}" @click="isMirrored = !isMirrored"></MyButton>
        </div>
        <div class="edit-room-buttons">
            <div class="number-button">
              <div class="logo"><v-icon name="md-tablerows-outlined"  scale="1.4"/></div>
              <div class="bottom">
                <div class="up" @click="changeRows(1)">+</div>
                <div class="down" @click="changeRows(-1)">-</div>
              </div>
            </div>
            <div class="number-button">
              <div class="logo"><v-icon name="md-tablerows-outlined" class="rotated" scale="1.4"/></div>
              <div class="bottom">
                <div class="up" @click="changeCols(1)">+</div>
                <div class="down" @click="changeCols(-1)">-</div>
              </div>
            </div>
         </div>
      </div>
      </div>
  </template>
  
  <script>

  import Table from './table.vue';
import MyButton from './ui/MyButton.vue';


  export default {
    name: 'Room',
    data() {
      return  {
        currentlySelectedStudent: null,
        chosenStudent: null,
        selectionDuration: 3,
        selectionOngoing: false,
        fullSelectionInterval: null,
        individualSelectionInterval: null,
        isMirrored: false,
        tableHeight: 40,
      } 
    },
    props: {
      config: Object,
      selectedClass: Object,
      menuOpen: Boolean,
    },
    components: {
      Table,
      MyButton,
    },
    watch: {
        config: function() {
            this.currentlySelectedStudent = null
            this.isMirrored = false
        },
        selectedClass: function() {
          this.currentlySelectedStudent = null
        } 
    },
    computed: {
      gridStyle() {
        return {
          gridTemplateColumns: `repeat(${this.config.cols}, 1fr)`,
          gridTemplateRows: `repeat(${this.config.rows}, 1fr)`
          //gridTemplateRows: `repeat(${this.config.rows}, ${this.tableHeight}px)`
        }
      },
      reversedTables() {
        return this.config.tables.slice().reverse()
      }
    },
    mounted: function() {
      let height = window.screen.height;
      if (height < 800) {
        this.tableHeight = 30;
      }
    },
    methods: {
      changeRows: function(amount) {
        this.$emit('changeRows', amount);
      },
      changeCols: function(amount) {
        this.$emit('changeCols', amount);
      },
      toggleMainMenu: function() {
        this.$emit('toggleMainMenuEvent');
      }, 
      start: function() {
      },
        startSelection: function() {
          this.individualSelectionInterval = setInterval(() => {
            this.chooseStudent()
          }, 200);
          setTimeout(() => {
            clearInterval(this.individualSelectionInterval)
          }, 3000)
        },
        chooseStudent: function() {
          this.currentlySelectedStudent = this.choose(this.selectedClass.students).id
          console.log("choosing")
        },
        choose: function (choices) {
          var index = Math.floor(Math.random() * choices.length);
          return choices[index];
        },
        toggleTable: function(index) {
            this.$emit('toggleTable', index);
        },
        generateOrder: function() {
            this.$emit("generateOrder");
        },
        openGroupBuilder: function() {
          this.$emit("openGroupBuilder")
        }
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  @import "./../assets/colors.scss";



  .mini-table {
    background-color: black;
    opacity: 1 !important;
    border-radius: 3px;
    width: 30px;
    height: 18px;
    display: inline-block;
    }
  .edit-room-buttons {
    display: flex;
    z-index: 13;
  }
  .number-button {
    display: flex;
    flex-direction: column;
    border: 2px solid black;  
    margin-right: 1rem;
    background-color: white;
    position: relative;
    height: max-content;
  }
  .number-button::after {
    content: "";
    position: absolute;
    top: 4px;
    left: 6px;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: -1;
  }
  .number-button .logo {
    display: flex;
    justify-content: center;
    padding: 0.2rem;
    border-bottom: 2px solid black;
  }
  .number-button .bottom {
    display: flex;
  }
  .number-button .up {
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    background-color: black;
    color: white;
  }
  .number-button .down {
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
  }
  .rotated {
    transform: rotate(90deg);
  }
  .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }
  .button-group .left {
    display: flex;
  }
  .button-group .left .button {
    margin-right: 1rem;
    z-index: 10;
  }
  .subtitle {
    width: calc(100% - 2rem);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 0.4rem;
}
.subtitle p {
  opacity: 0.7;
    font-size: 1.2rem;
    
    margin-bottom: 0;
    margin-top: 0;
    margin-right: 0.5rem;
}
.small-note {
  
    opacity: 0.7;
    max-width: 1000px;
    font-size: 1rem;
}
  .room-wrapper {
    width: 100%;
  }
  .room {
    width: 100%; 
    height: 500px;
    display: grid;
    grid-gap: 2px;
    border: 2px solid black;
    background-color: black;
  }

  @media only screen and (max-height: 800px) {
  .table {
    font-size: 0.8rem;
  }

}
@media only screen and (max-height: 750px) {
  .room {
    height: 400px;
  }

}
@media only screen and (max-height: 600px) {
  .room {
    height: 320px;
  }

}

@media only screen and (max-width: 1100px) {

.button-group {

}
.button-group .left {
  display: flex;
  flex-wrap: wrap;
}

}
  </style>
  