export function clearData() {
  localStorage.setItem('rooms', JSON.stringify([]))
  localStorage.setItem('classes', JSON.stringify([]))
}
export function storeRooms(rooms) {
  localStorage.setItem('rooms', JSON.stringify(rooms))
}
export function loadRooms() {
  try {
    const rooms = JSON.parse(localStorage.getItem('rooms'))
    return rooms ? rooms : []
  } catch (error) {
    return []
  }
}
export function storeClasses(classes) {
  localStorage.setItem('classes', JSON.stringify(classes))
}
export function loadClasses() {
  try {
    let classes = JSON.parse(localStorage.getItem('classes'))
    classes = classes ? classes : []
    // Migration to blocking Groups
    classes = classes.map(cla => {
      cla.students = cla.students.map(student => {
        if (!student.blockingGroup || student.blockingGroup === undefined) {
          student.blockingGroup = 0
        }
        return student
      })
      return cla
    })
    return classes
  } catch (error) {
    console.log(error);
    return []
  }
}
