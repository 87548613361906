<template>
    <div class="app">
        
      <!-- <div class="copyright">
        <div class="highlighted">
          
           <a href="mailto:claudio.brasser@gymneufeld.ch">
            <v-icon class="icon" name="md-build-outlined"  scale="0.7"/>
            Claudio Brasser</a>
        </div>
         <div class="coffee">
          <a target="_blank" href="https://buymeacoffee.com/cbrasser"><img src="@/assets/BMCButton.png"></a>
        </div>
        
      </div>
      -->
      <!-- Data import Menu -->
       <div class="header">
        <a href="/"><h1>Sitzplan</h1></a>
       </div>
       <div class="side-nav">
         <div class="menu-handle-alt" @click="toggleMenu('data')">
            <v-icon name="px-save"  scale="1.4"/>
            <div class="menu-handle-desc">Daten</div>
          </div>
          <div class="menu-handle-alt" @click="toggleMenu('class')">
            <v-icon name="si-googleclassroom" scale="1.4" :animation="selectedClass ? '' : 'pulse'"/>
            <div class="menu-handle-desc">Klassen</div>
          </div>
          <div class="menu-handle-alt" @click="toggleMenu('room')">
            <v-icon name="px-pin"  scale="1.5" :animation="selectedRoom ? '' : 'pulse'"/>
            <div class="menu-handle-desc">Zimmer</div>
          </div>

       </div>
      <div class="menu data-menu" :class="{open: dataMenuOpen}">
        <div class="menu-wrapper">
          <div class="menu-close" @click="toggleMenu('data')">
            <v-icon name="px-close-box"  scale="1.4"/>
          </div>
         
          <h2 class="picker-title">Daten verwalten</h2>
          <p class="menu-hint">
            Deine Klassen & Schulzimmer werden in deinem Browser auf deinem Gerät gespeichert. 
            Solltest du deine Browserdaten löschen oder einen anderen Browser verwenden, können diese verloren gehen.
            Hier kannst du deine Daten exportieren, damit du ein Backup auf deinem Computer hast. Die exportierte Datei
            kann später jederzeit wieder importiert werden. 
</p>
          <Export v-on:dataImported="$event => loadData(true)"></Export>
        </div>
      </div>
      <!-- Class Menu-->
      <div class="menu class-menu" :class="{open: classMenuOpen}">
        <div class="menu-wrapper">
          <div class="menu-close" @click="toggleMenu('class')">
            <v-icon name="px-close-box"  scale="1.4"/>
          </div>
          
          <h2 class="menu-title">Meine Klassen</h2>
          <div class="small-hint" v-if="classes.length == 0">Noch keine Klassen. Gib den Namen einer Klasse ein und wähle das "+" Icon um eine Klasse hinzuzufügen</div>
          <div class="list class-list">
            <div class="option" v-for="klasse in classes" :key="klasse.id" :class="{active: klasse.id === selectedClass.id}">
              <div class="option-label" @click="selectClass(klasse.id)">{{ klasse.name }}</div>
              <div class="option-bottom">
                <div class="option-action option-edit" @click="editClass(klasse.id)"><v-icon  name="ri-edit-circle-fill" /></div>
              <div class="option-action option-delete" @click="deleteClass(klasse.id)"><v-icon  name="io-remove-circle" /></div>
              </div>
            </div>
          </div>
          <div class="input-group">
              <input :class="{valid: newClassName.length > 0}" type="text" v-model="newClassName" placeholder="Neue Klasse" v-on:keyup.enter="addNewClass()"/>
            <div class="confirm" @click="addNewClass()" :class="{valid: newClassName.length > 0}"><v-icon class="icon" name="io-add-circle" /></div>
            
          </div>
          <MyButton @click="importModuleOpen = true" :text="'Von CSV-Datei importieren'"></MyButton>
        </div>
      </div>
      <!-- Room Menu -->
      <div class="menu room-menu" :class="{open: roomMenuOpen}">
        <div class="menu-wrapper">
          <div class="menu-close" @click="toggleMenu('room')">
            <v-icon name="px-close-box"  scale="1.4"/>
          </div>
          
          <h2 class="picker-title">Meine Schulzimmer</h2>
          <div class="small-hint" v-if="rooms.length == 0">Noch keine Schulzimmer. Gib den Namen eines Zimmers ein (z.B. 1.55) und wähle das "+" Icon.</div>
          <div class="list room-list">
            <div class="option" v-for="room in rooms" :key="room.id" :class="{active: room.id === selectedRoom.id}">
              <div class="option-label" @click="selectRoom(room.id)">{{ room.name }}</div>
              <div class="option-bottom">
                <div class="option-action option-delete" @click="deleteRoom(room.id)"><v-icon name="io-remove-circle" /></div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <input :class="{valid: newRoomName.length > 0}" type="text" v-model="newRoomName" placeholder="Neues Zimmer" v-on:keyup.enter="addNewRoom()"/>
            <div class="confirm" @click="addNewRoom()" :class="{valid: newRoomName.length > 0}"><v-icon class="icon" name="io-add-circle" /></div>
          </div>
        </div>
      </div>
      
  
      <div class="main-window">
        <div class="room-top">
          <div class="tags">
            <div class="tag">
                      <v-icon class="t-icon" name="si-googleclassroom"  scale="1.8"/>
                      <div class="tag-right">
                          <div class="tag-header">Klasse</div>
                          <div v-if="!selectedClass">
                            Keine Klasse ausgewählt
                            <v-icon @click="toggleMenu('class')" class="t-icon action" name="px-mouse"  scale="1.2"/>
                          </div>
                          <div v-else>
                            {{ selectedClass.name }}
                          </div>

                      </div>
                      
            </div>
            <div class="tag">
                      <v-icon class="t-icon" name="px-pin"  scale="1.8"/>
                      <div class="tag-right">
                          <div class="tag-header">Zimmer</div>
                          <div v-if="!selectedRoom">
                            Kein Zimmer ausgewählt
                            <v-icon @click="toggleMenu('room')" class="t-icon action" name="px-mouse"  scale="1.2"/>
                          </div>
                          <div v-else>
                            {{ selectedRoom.name }}
                          </div>

                      </div>
                      
            </div>
          </div>
        <div class="helper" v-if="selectedRoom && selectedClass">
          <tippy content="Klicke einen Tisch an um ihn zu aktivieren. Wenn genügend Tische ausgewählt sind, kann eine zufällige Ordnung generiert werden.">
          <v-icon class="t-icon" name="ri-questionnaire-fill"  scale="1.8"/>
        </tippy>
        </div>
      </div>
          
          <Room v-if="selectedRoom && selectedClass" :config="selectedRoom" :selectedClass="selectedClass" :menu-open="mainMenuOpen"
          v-on:toggleMainMenuEvent="$event => toggleMainMenu()"
          v-on:toggleTable="$event => toggleTable($event)"
          v-on:changeRows="$event => updateRoomRows($event)"
          v-on:changeCols="$event => updateRoomCols($event)"
          v-on:generateOrder="$event => generateOrder($event)"
          v-on:openGroupBuilder="$event => groupBuilderOpen = true">
          </Room>

          
      </div>
          <!-- Overlay Modules -->
          <Klasse v-if="selectedClass" :config="selectedClass"  :class="{open: editClassOpen}"
            v-on:addStudent="$event => addStudent($event)" 
            v-on:updateStudents="$event => updateStudents($event)"
            v-on:changeClassName="$event => changeClassName($event)"
            v-on:closeClassMenu="$event => closeClassMenu()">
          </Klasse>
        <ImportModule v-on:closeImportModule="importModuleOpen=false" :class="{open: importModuleOpen}" v-on:importNames="$event => addClassWithNames($event)"></ImportModule>
        <GroupBuilder v-if="selectedClass" v-on:closeGroupBuilder="groupBuilderOpen=false" :class="{open: groupBuilderOpen}" :config="selectedClass"></GroupBuilder>
    </div>
  </template>
  
  <script>
  const {changeRoomSize, getEmptyRoomConfig, getEmptyClassConfig, createClassFromName, createClassFromNameAndStudents ,createRoomFromName, createStudent } = require('./config.js');
  const {storeRooms, storeClasses, loadRooms, loadClasses} = require('./storage.js')
  import Room from './components/room.vue';
  import Klasse from './components/klasse.vue';
  import ImportModule from './components/ImportModule.vue';
  import GroupBuilder from './components/GroupBuilder.vue'
  import Export from './components/Export.vue'
  import MyButton from './components/ui/MyButton.vue';
  import { Tippy } from 'vue-tippy';

  // import { ipcRenderer } from 'electron'
  
  export default {
    name: 'Generator',
    data() {
      return  {
        rooms: [getEmptyRoomConfig()],
        classes: [getEmptyClassConfig()],
        selectedClass: {},
        selectedRoom: {},
        newClassName: "",
        newRoomName: "",
        editClassOpen: false,
        importModuleOpen: false,
        groupBuilderOpen: false,
        santaActive: false,
        classMenuOpen: false,
        roomMenuOpen: false,
        dataMenuOpen: false,
      } 
    },
    created () {
      this.loadData(false);
      this.selectedClass = this.classes[0];
      this.selectedRoom = this.rooms[0];
      this.assignDefaultSelections()
    },
    mounted () {
      setTimeout( () => {
        this.santaActive = true;
      }, 2000)
    },
    props: {
    },
    components: {
      Room,
      Klasse,
      ImportModule,
      GroupBuilder,
      Export,
      MyButton,
      Tippy
      //ChristmasBackground
    },
    computed: {
    },
    methods: {
      toggleMenu: function(name) {
        if(name === "data") {
          this.dataMenuOpen = !this.dataMenuOpen;
          this.roomMenuOpen = false;
          this.classMenuOpen = false;
        }
        if(name === "class") {
          this.dataMenuOpen = false;
          this.roomMenuOpen = false;
          this.classMenuOpen = !this.classMenuOpen;
        }
        if(name ==="room") {
          this.dataMenuOpen = false;
          this.roomMenuOpen = !this.roomMenuOpen;
          this.classMenuOpen = false;
        }
      },
      toggleMainMenu: function() {
        this.mainMenuOpen = !this.mainMenuOpen
      },
      saveClasses: function() {
        storeClasses(this.classes);
      },
      saveRooms: function() {
        storeRooms(this.rooms)
      },
      saveData: function() {
        this.saveClasses();
        this.saveRooms();
      },
      assignDefaultSelections: function() {
        if (!this.selectedClass && this.classes.length > 0) {
          this.selectedClass = this.classes[0];
        }
        if (!this.selectedRoom && this.rooms.length >0) {
          this.selectedRoom = this.rooms[0]
        }
      },
      loadData: function(imported) {
        // ipcRenderer.send('loadData', 'ping', {a: 2})
        console.log("loading data")
        this.classes = loadClasses();
        this.rooms = loadRooms();
        this.assignDefaultSelections()
        //if (this.classes.length > 0 || this.rooms.length > 0) {
        //  this.welcomeModuleOpen = false
        //}
        if (imported) {
          this.dataMenuOpen = false
        }
      },
      generateOrder: function() {
        const numAvailableTables = this.selectedRoom.tables.filter(t => t.available).length;
        if(numAvailableTables < this.selectedClass.students.length) {
          alert("Mehr Schüler:innen als verfügbare Tische!")
          return
        }
        let foundValidConfig = false
        let randomStudentOrder = []
        while (!foundValidConfig) {
          foundValidConfig = true
          randomStudentOrder = this.selectedClass.students.map(student => ({student, sort: Math.random()})).sort((a,b) => a.sort - b.sort).map(({student}) => student);
          this.selectedRoom.tables.map(t => t.studentId = "");
          let availableTables = this.selectedRoom.tables.filter(t => t.available);
          for(let i = 0; i<randomStudentOrder.length; i++) {
            availableTables[i].studentId = randomStudentOrder[i].id
            const valid = this.checkBlockingGroups(availableTables, i, randomStudentOrder[i].blockingGroup)
            if (!valid) {
              foundValidConfig = false
            }
          }
          
        }
        
        // console.log(randomStudentOrder);
        
      },
      checkBlockingGroups: function(availableTables, indexToBePlaced, blockingGroup) {
        if(blockingGroup == 0) {
          return true
        }
        const tableToBePlaced = availableTables[indexToBePlaced]
        const tableLeft = availableTables.find(t => t.index == tableToBePlaced.index -1 )
        const tableRight = availableTables.find(t => t.index == tableToBePlaced.index + 1 )
        const tableTop = availableTables.find(t => t.index == tableToBePlaced.index - this.selectedRoom.cols )
        const tableBottom = availableTables.find(t => t.index == tableToBePlaced.index + this.selectedRoom.cols)
      
        const studentLeft = tableLeft ? this.selectedClass.students.find(s => s.id == tableLeft.studentId) : null
        const studentRight = tableRight ? this.selectedClass.students.find(s => s.id == tableRight.studentId) : null
        const studentTop = tableTop ? this.selectedClass.students.find(s => s.id == tableTop.studentId) : null
        const studentBottom = tableBottom ? this.selectedClass.students.find(s => s.id == tableBottom.studentId) : null
        
        if (studentLeft && studentLeft.blockingGroup === blockingGroup) {
          return false
        }
        if (studentRight && studentRight.blockingGroup === blockingGroup) {
          return false
        }
        if (studentTop && studentTop.blockingGroup === blockingGroup) {
          return false
        }
        if (studentBottom && studentBottom.blockingGroup === blockingGroup) {
          return false
        }
        return true
      },
      tablesAreNextToEachOther: function(index_1, index_2){
        // students horizontally next to each other
        console.log(`Rows: ${this.selectedRoom.rows}, Cols: ${this.selectedRoom.cols}`)
        console.log(`Index 1: ${index_1}, Index 2: ${index_2}`)
        if (Math.abs(index_1 - index_2) === 1 && index_1 % (this.selectedRoom.cols -1) != 0) {
          console.log(`Rows: ${this.selectedRoom.rows}, Cols: ${this.selectedRoom.cols}`)
          console.log("next to each other")
          return true
          
        }
        if (Math.abs(index_1 - index_2) == this.selectedRoom.cols -1) {
          console.log("next to each other")
          return true
        }
      },
      toggleTable: function(index) {
        let table = this.selectedRoom.tables[index]; 
        table.available = !table.available;
        table.studentId= "";
        this.saveData()
      },
      updateRoomRows: function(amount) {
        this.selectedRoom = changeRoomSize(this.selectedRoom, this.selectedRoom.rows + amount, this.selectedRoom.cols)
        this.saveData()
      },
      updateRoomCols: function(amount) {
        this.selectedRoom = changeRoomSize(this.selectedRoom, this.selectedRoom.rows, this.selectedRoom.cols + amount)
      },
      addStudent: function(name) {
        if (name.length === 0) return;
        this.selectedClass.students.push(createStudent(name))
        this.saveData()
      },
      addStudents: function(names) {
        names.forEach(name => {
          this.addStudent(name)
        });
      },
      addClassWithNames: function(config) {
        this.importModuleOpen = false;
        this.addNewClassWithStudents(config.className, config.names);
      },
      updateStudents: function(students) {
        if (this.selectedRoom) {
          this.selectedRoom.tables.map(t => t.studentId = "")
        }
        this.selectedClass.students = students;
        this.saveData()
      },
      changeClassName: function(name) {
        if(name.length === 0) return;
        this.selectedClass.name = name;
        this.saveData()
      },
      updateRoom: function() {
        this.rooms = this.rooms.filter(room => room.id === this.selectedRoom.id).push(this.selectedRoom);
        this.saveData()
      },
      updateClass: function() {
        this.classes = this.classes.filter(klasse => klasse.id === this.selectedClass.id).push(this.selectedClass);
        this.saveData()
      },
      selectClass: function(id) {
        this.selectedRoom.tables.map(t => t.studentId = "");
        this.selectedClass = this.classes.find(c => c.id === id);
      },
      editClass: function(id) {
        this.selectedClass = this.classes.find(c => c.id === id);
        this.editClassOpen = true;
      },
      closeClassMenu: function() {
        this.editClassOpen = false;
      },
      selectRoom: function(id) {
        this.selectedRoom.tables.map(t => t.studentId = "");
        this.selectedRoom = this.rooms.find(r => r.id === id)
        },
        deleteClass: function(id) {
          console.log('deleting class: ' + id)
          this.classes = this.classes.filter(c => c.id !== id)
          this.selectedClass=this.classes[0]
          this.saveData()
        },
        deleteRoom: function(id) {
          this.rooms = this.rooms.filter(r => r.id !== id)
          this.selectedRoom=this.rooms[0]
          this.saveData()
        },
        addNewClass: function() {
          if (this.newClassName.length  === 0) return;
          this.classes.push(createClassFromName(this.newClassName))
          this.newClassName = ""
          if (!this.selectedClass) {
            this.selectedClass = this.classes[0]
          }
          this.saveData()
          // this.classMenuOpen =false
        },
        addNewClassWithStudents: function(name, students) {
          this.classes.push(createClassFromNameAndStudents(name, students));
          if (!this.selectedClass) {
            this.selectedClass = this.classes[0]
          }
          this.saveData()
        },
        addNewRoom: function() {
          if (this.newRoomName.length  === 0) return;
          this.rooms.push(createRoomFromName(this.newRoomName))
          this.newRoomName = ""
          if (!this.selectedRoom) {
            this.selectedRoom = this.rooms[0]
          }
          this.saveData()
          this.roomMenuOpen = false
        },
    }
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss">
  @import "./assets/colors.scss";
  
a {
  color: black;
  text-decoration: none;
}

  .room-top {
    display: flex;
    justify-content: space-between;
    padding-right: 30px;
  }
  .tags {
    display: flex;
    margin-bottom: 0.5rem;
  }

  .tag {
    display: flex;
    align-items: center;
    margin-right: 1.5rem;
}
.tag p {
    margin: 0;
}
.tag-header {
    font-weight: bold;
}
.t-icon {
    margin-right: 0.5rem;
    
}
.t-icon.action {
      cursor: pointer;
      animation: pulse 2s linear infinite;
    }

    @keyframes pulse {
  0%   {
      transform: scale(1.1);       
    }

    50% {
      transform: scale(0.8);
    }
  100%   {
    transform: scale(10.1rem);
  }
}


  .create-menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .create-menu input {
    margin-right: 1rem;
  }
  .create-menu .create-button {
    background-color: #fca5a5;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    padding: 6px;
    border-radius: 50%;
    transition: 0.2s ease-in-out;
  }
  .create-menu .create-button.valid {
    cursor: pointer;
    background-color: #a3be8c;
  }
  .create-menu .create-button.valid:hover {
    background-color: #16a34a;
  }
  
  .app {
    display: flex;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
    background-color: $background;
    flex-direction: column;
  }
  .main-window {
    padding-left: 80px;
    padding-right: 1rem;
    position: relative;
  
  }
  .header {
    padding-left: 80px;
  }
  .side-nav {
    position: absolute;
    left: 0;
    width: 80px;
    display: flex;
    height: 100vh;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 3;
  }
  
  .menu {
    position: absolute;
    top: 10px;
    background-color: white;
    color: black;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    z-index: 5;
    left: 0;
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
    border: 2px solid black;
    
  }
  .menu.open {
    transform: translateX(10px);
    z-index: 7;
  }
  .menu .menu-wrapper {
    position: relative;
    padding: 20px;
    padding-right: 50px;
  }
  .menu .menu-wrapper .menu-close {
    position: absolute;
    top: 2px;
    right: 2px;
    cursor: pointer;
  }
  .menu-hint {
    max-width: 500px;
  }
  .menu .menu-handle {
    display: flex;
    width: 60px;
    height: 60px;
    position: absolute;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    left: 100%;
    top:0;
    background-color: black;
    color: white;
  }
  .menu .menu-handle svg {
    font-size: 2rem;
  }
  .menu .menu-handle::after {
      background-color: #F3F461;
      top: 0;
      left: 0;
      transform: translate(3px, 3px);
      z-index: -1;
  }
  .menu .menu-handle::before {
      background-color: #943737;
      transform: translate(5px, 5px);
      z-index: -2;
      top: 0;
      left: 0;
  }
  .menu .menu-handle::before, .menu .menu-handle::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in-out;
  }
  .menu .menu-handle:hover::after {
      transform: translate(2px, 2px);
  }
  .menu .menu-handle:hover::before {
      transform: translate(4px, 4px);
  }

  .menu-handle-alt {
    background-color: white;
    border: 2px solid black;
    border-radius: 4px;
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 1rem;
    transition: all 0.3s ease-in-out;
    flex-direction: column;
  }
  .menu-handle-desc {
    font-size: 0.7rem;
  }
  .menu.open .menu-handle-alt {
    opacity: 0;
    cursor: none;
    pointer-events: none;
  }
  .menu-handle-alt svg {
    font-size: 2rem;
  }

  .class-menu {
    z-index: 6;
  }
  .room-menu {
    z-index: 5;
  }
  .data-menu {
    z-index: 4;
  }
  .class-menu .menu-handle {
    top: 80px;
  }
  .room-menu .menu-handle {
    top: 160px;
  }
  .class-menu .menu-handle-alt {
    top: 60px;
  }
  .room-menu .menu-handle-alt {
    top: 120px;
  }
  .close-main-menu {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
  }
  
  .configuration-helper {
    margin-top: 3rem;
    z-index: 5;
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    position: relative;
  }
  .configuration-helper h1 {
    margin-bottom: 2rem;
    cursor: pointer;
  }
  
  .main-class {
    width: calc(50vw - 40px);
    height: 100vh;
    position: absolute;
    top:0;
    left:-50vw;
    z-index: 4;
    background-color: #d8dee9;
    padding: 20px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
  }
  .main-class.open {
    left: 30vw;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }
  
  
  .list {
    display: flex;
    flex-wrap: wrap;
  }
  .list .option {
    background-color: white;
    display: flex;
    min-width: 80px;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0px;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    margin-right: 0.6rem;
    margin-bottom: 0.3rem;
    transition: all 0.3s ease-in;
    border: 3px solid black;
    position: relative;
    color: black;
  }
  .list .option.active {
    cursor:default;
  }
  .list .option::after {
    width: 100%;
    height: 100%;
    background-color: black;
    position: absolute;
    top: 0px;
    left: 0px;
    transition: all 0.2s ease-in-out;
    content: "";
    z-index: -1;
  }
  
  .list .option.active::after {
    transform: translate(8px, 12px) scale(1.02);
  }
  .list .option-label {
    text-align: center;
    font-size: 1.2rem;
  }
  .list .option-bottom {
    display: flex;
    flex-wrap: nowrap;
  }
  .list .option-bottom .option-action{
    flex: 50%;
    display: flex;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
  }
  .list .option-bottom .option-edit {
    color: #8fbcbb;
  }
  .list .option-bottom .option-delete {
    color: #bf616a;
  }
  
  .option-label {
    flex-grow: 1;
    padding: 5px;
  }
  .option-delete, .option-edit {
    cursor: pointer;
  }
  
  
  
  .group-builder-toggle {
    font-size: 0.6rem;
    position: absolute;
    bottom: 35px;
    right: 5px;
    color: #334155 !important;
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 4px;
    background-color: #fee2e2;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .group-builder-toggle:hover {
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  }






  
  </style>
  