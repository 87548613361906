import { createApp } from 'vue'
import App from './App.vue'
import Home from './Home.vue';
import Generator from './Generator.vue';

import { OhVueIcon, addIcons } from "oh-vue-icons";
import {PxMouse, RiQuestionnaireFill, PxPin, PxCloseBox, PxSave, PxMail, PxCoffeeAlt, MdBuildOutlined, PxTeach, MdMoneyoffOutlined, BiQuestionSquareFill, MdTablerowsOutlined,MdRoom, SiGoogleclassroom, MdSaveasOutlined, HiSolidMenuAlt1, IoCreateOutline, IoRemoveCircle, IoPencilOutline, IoAddCircle, RiEditCircleFill, IoClose, CoCloudUpload, CoCloudDownload} from "oh-vue-icons/icons";
import {VueCsvImportPlugin} from "vue-csv-import";

import { createWebHistory, createRouter } from 'vue-router'

import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/material.css'
import 'tippy.js/dist/backdrop.css'
import 'tippy.js/animations/shift-away.css'

addIcons(PxMouse, RiQuestionnaireFill, PxPin, PxCloseBox, PxSave, PxMail, PxCoffeeAlt, MdBuildOutlined, PxTeach, MdMoneyoffOutlined, BiQuestionSquareFill, MdTablerowsOutlined, MdRoom, SiGoogleclassroom, MdSaveasOutlined, HiSolidMenuAlt1, IoCreateOutline, IoRemoveCircle, IoPencilOutline, IoAddCircle, RiEditCircleFill, IoClose, CoCloudUpload, CoCloudDownload);

const routes = [
    { path: '/', component: Home },
    { path: '/generator', component: Generator },
  ]
  
  const router = createRouter({
    history: createWebHistory(),
    routes, 
  })

const app = createApp(App).use(router).use(VueCsvImportPlugin).use(VueTippy, {
    defaultProps: { theme: "material", animateFill: true, arrow: true },
});
app.component("v-icon", OhVueIcon);
app.mount('#app')

