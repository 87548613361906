<template>
    <div class="overlay">
    <div class="overlay-module-wrapper">
        <div class="overlay-module-content import-module">
            <div @click="openClassSecretSettings" class="overlay-module-additional-open">
          
        </div>
        <h2>CSV Import</h2>
        <div class="import-module">
        <div @click="closeModule" class="overlay-module-close"><v-icon  name="px-close-box" scale="1.4"/></div>
        <vue-csv-import
        class="import-dialog"
        v-model="csvData"
        :fields="{vorname: {required: false, label: 'Vorname'}, nachname: {required: false, label: 'Nachname'}}"
        :text="importText">
        <tippy content="Importiere eine Klasse aus einer CSV Datei. Die Datei darf nur 2 Spalten mit den Vor- und Nachnamen der Schüler:innen haben">

        <vue-csv-input class="import-input" name="CSV Datei"></vue-csv-input>
        </tippy>
        <vue-csv-errors class="import-errors"></vue-csv-errors>
        <tippy content="Wähle diese Option, falls deine CSV-Datei eine Titelreihe enthält">
        <vue-csv-toggle-headers class="import-headers"></vue-csv-toggle-headers>
    </tippy>
    <tippy content="Weise dem Feld Vor- und Nachname die korrekte Spalte aus deiner CSV-Datei zu. In der Liste unten siehst du eine Vorschau. ">
        <vue-csv-map class="import-map"></vue-csv-map>
    </tippy>
    
    </vue-csv-import>
</div>
    <h3>Namen in der Liste ({{ csvData.length }})</h3>
    <div class="student-list">
        <div class="student" v-for="name in importedNames" v-bind:key="name">{{ name }}</div>
    </div>
    <div>
        <input v-model="className" placeholder="Klassenname">
        <MyButton class="button" :text="'Importieren'" @click="importNames" v-if="csvData.length > 0 && className.length > 0"></MyButton>
    </div>
    
    </div>
</div>
</div>

</template>
<script>
  import { Tippy } from 'vue-tippy';
import MyButton from './ui/MyButton.vue';

export default {
    name: "ImportModule",
    data() {
        return {
            csvData: [],
            className: "",
            importText: {
                errors: {
                    fileRequired: 'Eine Datei wird benötigt',
                    invalidMimeType: "Nur CSV-Dateien sind erlaubt"
                },
                toggleHeaders: 'Die Spalten haben Titel',
                submitBtn: 'Importieren',
                fieldColumn: 'Feld',
                csvColumn: 'Spalte'
            }
        }
    },
    components: {
        Tippy,
        MyButton
    },
    methods: {
        importNames() {
            this.$emit("importNames", {className: this.className, names: this.importedNames})
        },
        closeModule() {
            this.$emit("closeImportModule")
        },
    },
    computed: {
        importedNames() {
            return this.csvData ? this.csvData.map(student => student.vorname + " " + student.nachname) : [];
        }
    }
}
</script>

<style lang="scss" scoped>
@import "./../assets/colors.scss";
@import "./../assets/overlays.scss";

.import-module {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.import-errors {
    flex: 100%;
    flex-shrink: 0;
    border: 2px solid #bf616a;
    padding: 0.4rem;
    margin-top: 1rem;
}


.student-list {
    display: flex;
    flex-wrap: wrap;
}
.student {
    background-color: #e5e9f0;
    padding: 2px;
    border-radius: 2px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    margin: 0.5rem;
}

.overlay-module-additional-open {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

</style>